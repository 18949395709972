import { render, staticRenderFns } from "./FormInputObject.vue?vue&type=template&id=dd07dbde&scoped=true"
import script from "./FormInputObject.vue?vue&type=script&lang=js"
export * from "./FormInputObject.vue?vue&type=script&lang=js"
import style0 from "./FormInputObject.vue?vue&type=style&index=0&id=dd07dbde&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd07dbde",
  null
  
)

export default component.exports