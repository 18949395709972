<template>
  <div class="demo-list">
    <div class="box-search mb-5">
      <div class="row">
        <div class="col-12 position-relative">
          <div class="border rounded p-1 pr-2">
            <span class="text-left">検索条件</span>
          </div>
          <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
              <template #activator>
                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
              </template>
              <v-list-item>
                <div class="border border-top-0 rounded p-1 pr-2">
                  <div class="col-12 p-0 m-0 text-right">
                    <button @click="clearAllSearch" type="button" class="btn btn-grey">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                      クリア
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      年度
                      <date-picker autocomplete="off" v-model="dataSearch.year" id="year" name="year" :config="optionYear"
                                   @input="validate('year')"></date-picker>
                      <span class="text-danger">{{ errors.year }}</span>
                    </div>
                    <div class="col-3">
                      対象日
                      <div class="row align-items-center">
                        <div class="col-5 mt-0 pt-0">
                          <date-picker autocomplete="off" v-model="dataSearch.from_date" id="from_date" name="from_date" :config="options"
                                       @input="validate('to_date')"></date-picker>
                        </div>
                        <div class="col-1 text-center pt-0">-</div>
                        <div class="col-5 mt-0 pt-0">
                          <date-picker autocomplete="off" v-model="dataSearch.to_date" id="to_date" name="to_date" :config="options"
                                       @input="validate('to_date')"></date-picker>
                        </div>
                        <span class="text-danger">{{ errors.to_date }}</span>
                        <span class="text-danger">{{ In_ValRng_TermOver }}</span>
                      </div>
                    </div>
                    <div class="col-2">
                      氏名
                      <input id="name" name="name" type="text" class="form-control" placeholder="氏名 or 氏名カナ"
                             v-model="dataSearch.patient_name" @blur="validate('patient_name')"
                             @keyup="validate('patient_name')" />
                      <span class="text-danger">{{ errors.patient_name }}</span>
                    </div>
                    <div class="col-2">
                      利用券整理番号
                      <input v-model="dataSearch.patient_ticket" type="text" class="form-control" placeholder=""
                             @blur="validate('patient_ticket')" @keyup="validate('patient_ticket')" />
                      <span class="text-danger">{{ errors.patient_ticket }}</span>
                    </div>
                    <div class="col-2">
                      保険者
                      <select v-model="dataSearch.insurer" class="form-select">
                        <option :value="null"></option>
                        <option v-for="insurer in insurerArr" :key="insurer.id + 'insurer'" :value="insurer.id">
                          [{{ insurer.client_organ_code }}]
                          {{ insurer.client_organ_name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      支払代行機関
                      <select v-model="dataSearch.payment_agency_id" class="form-select">
                        <option :value="null"></option>
                        <option value="null_data">設定なし</option>
                        <option v-for="item in listPaymentAgency" :key="item.value + 'item'" :value="item.value">
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      保健指導区分
                      <select v-model="dataSearch.health_guide_div" class="form-select">
                        <option :value="null"></option>
                        <option v-for="item in code" :key="item.text + 'item'" :value="item.value">
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      契約区分
                      <select v-model="dataSearch.health_guidance_contract_div" class="form-select">
                        <option :value="null"></option>
                        <option v-for="item in listContractCode" :key="item.text + 'item'" :value="item.value">
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      本人家族区分
                      <select v-model="dataSearch.family_code" class="form-select">
                        <option :value="null"></option>
                        <option v-for="item in listFamilyCode" :key="item.text + 'item'" :value="item.value">
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-4 align-items-center">
                      <label>実施時点</label>
                      <div class="row">
                        <div v-for="item in listExecutionTime.filter(({value}) => value!=30)" :key="item.text + item.value" class="col-auto ml-3 form-check">
                            <label class="form-check-label" :for="item.value">{{ item.text }}</label>
                            <input checked type="checkbox"  class="form-check-input value-code-at-the-time-of-making-invoice" :id="item.value" />
                        </div>
                      </div>
                    </div>
                    <div v-if="checkTab == 2" class="col-2">
                      請求日
                      <date-picker autocomplete="off" v-model="dataSearch.date_invoice" id="date_invoice" name="date_invoice" :config="options"
                                   @input="validate('to_date')"></date-picker>
                    </div>
                    <div class="col-12 text-right">
                      <button @click="getSearch()" type="button" class="btn btn-success mt-5">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        検索
                      </button>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </div>
    </div>

    <div class="tab d-flex flex-column h-100 overflow-auto">
      <ul class="nav nav-pills pl-0" id="pills-tab" role="tablist">
        <li class="nav-item border">
          <button class="nav-link active rounded-0" id="not-created-tab" data-bs-toggle="pill"
            data-bs-target="#not-created" @click="checkTab = 1" type="button" role="tab" aria-controls="not-created" aria-selected="true">
            未作成
          </button>
        </li>
        <li class="nav-item border">
          <button class="nav-link rounded-0" @click="checkTab = 2" id="create-tab" data-bs-toggle="pill" data-bs-target="#create"
            type="button" role="tab" aria-controls="create" aria-selected="false">
            作成済
          </button>
        </li>
        <li class="nav-item ml-auto">
            <div v-if="checkTab == 1">
              ({{ (dataCreate.checkedRows?.length ?? 0) }} / {{ (listDataNotCreated?.length ?? 0) }})
            </div>
            <div v-if="checkTab == 2">
              ({{ (data_delete_reception_id?.length ?? 0) }} / {{ (listDataCreated?.length ?? 0) }})
            </div>
        </li>
      </ul>
      <div :class="['tab-content', { 'd-flex h-100 overflow-auto' : this.listDataNotCreated.length }]" id="pills-tabContent">
         <!-- 未作成一覧 -->
        <div class="tab-pane fade show active" id="not-created" role="tabpanel" aria-labelledby="not-created-tab">
          <div class="col-12 p-0 border tab-not-created d-flex flex-column h-100">
            <!-- Not Created Tab -->
            <div class="data-table-container">
              <v-data-table :fixed-header="true" :height="'100%'" id="tableHighlightOne" :headers="headersListNotCreate" :items="listDataNotCreated"
                disable-pagination hide-default-header hide-default-footer
                class="elevation-1 data-table table table-responsive table-hover tableHighlightOne">
                <template v-slot:header="{ props: { headers } }">
                  <thead class="v-data-table-header">
                    <tr>
                      <th v-for="(item, index) in headers" :key="item.text + 'header' + index" class="text-nowrap"
                        v-bind:class="[
                          item.sortable ? 'sortable' : '',
                          sortByHeadersListNotCreate.sortBy == item.value
                            ? 'active'
                            : '',
                          sortByHeadersListNotCreate.descending
                            ? 'desc'
                            : 'asc',
                        ]" @click="item.sortable ? changeSort(item.value) : ''">
                        <input v-if="index == 1" @click="checkAllNotCreate" class="check-all-list-not-create"
                          type="checkbox" />
                        <span v-else>{{ item.text }}</span>
                        <v-icon v-if="item.sortable" class="v-data-table-header__icon" small>mdi-arrow-up</v-icon>
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="item.id + '-data-not-create-' + index"
                      :id="'highlightOne' + index" @click="changeCellHighlightOne(index)">
                      <td>
                        <i @click="showDialogError(item.id, item.execution_time)" ref="iconErrCreate" :data-type="item.execution_time" :id="item.id"
                          class="fa-1x fa fa-exclamation-triangle text-danger d-none"></i>
                      </td>
                      <td>
                        <input :value="item.id" @change="checkNotCreate(item.id, item.execution_time)" class="checkbox-list-not-create"
                          type="checkbox" :id="item.id" :data-type="item.execution_time" />
                      </td>
                      <td class="text-nowrap">
                        {{ String(item.contract_fiscal_year).slice(-2) }}年度
                        (第{{ item.health_guidance_period }}期)
                      </td>
                      <td class="text-nowrap">{{ String(item.patient_id).padStart(10, "0") }}</td>
                      <td class="text-nowrap">{{ item.patient_name_kana }}</td>
                      <td class="text-nowrap">
                        {{ item.patient_birthday_and_sex }}
                      </td>
                      <td class="text-nowrap">
                        {{ item.use_ticket_ref_number }}
                      </td>
                      <td class="text-nowrap">
                        {{ item.health_guidance_div }}
                      </td>
                      <td class="text-nowrap">{{ item.insurer_id }}</td>
                      <td class="text-nowrap">
                        {{ item.health_guidance_contract_div }}
                      </td>
                      <td class="text-nowrap">{{ item.person_familiy_div }}</td>
                      <td class="text-nowrap">{{ item.payment_agency_id }}</td>
                      <td class="text-nowrap">
                        {{ item.where_to_report_invoice }}
                      </td>
                      <td class="text-nowrap">{{ item.execution_time == "途中終了" && item.support_stop_reason ? getExecutionTimeCode(item.support_stop_reason) :  item.execution_time}}</td>
                      <td class="text-nowrap">{{ item.object_date }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </div>
            <!-- 未作成一覧 フッタ -->
            <Pagination :total-page="totalPageNotCreated" :current-page="currentPageNotCreated"
                        @onHandleChangePage="onHandleChangePageNotCreated" />
            <div class="col-12 pr-0 row justify-content-end margin-top-20">
              <div class="col-2">
                請求日
                <date-picker autocomplete="off" id="year" name="year" :config="options" v-model="dataCreate.claim_date"></date-picker>
                <span class="text-danger">{{ checkValidateSaveNotCreate.claim_date }}</span>
              </div>
              <div class="col-2">
                消費税率
                <div class="position-relative">
                  <input type="number" class="form-control pr-10" placeholder="" v-model="dataCreate.tax_rate" />
                  <p class="position-absolute percent">%</p>
                </div>
                <span class="text-danger">{{ checkValidateSaveNotCreate.tax_rate }}</span>
              </div>
              <div class="col-2 pr-0">
                消費税 端数処理区分
                <select v-model="dataCreate.tax_fractionating_div" class="form-select">
                  <option v-for="item in listCodeFraction" :key="item.text + 'item'" :value="item.value">
                    {{ item.text }}
                  </option>
                </select>
                <span class="text-danger">{{ checkValidateSaveNotCreate.tax_fractionating_div }}</span>
              </div>
              <div class="col-1 text-right pr-0">
                <button @click="isShowDialogSaveNotCreate = true" type="button" class="btn btn-primary mt-5 pl-5 pr-5">
                  <i class="fa fa-database" aria-hidden="true"></i>
                  作成
                </button>
              </div>
            </div>
            <!-- Not Created Tab End -->
          </div>
        </div>
        <!-- 作成済一覧 -->
        <div class="tab-pane fade border" id="create" role="tabpanel" aria-labelledby="create-tab">
          <!-- Created Tab -->
          <div class="data-table-container">
            <v-data-table :fixed-header="true" :height="'100%'" id="tableHighlight" :headers="headersListCreate" :items="listDataCreated" disable-pagination
              hide-default-footer hide-default-header class="data-table table table-responsive table-hover tableHighlight">
              <template v-slot:header="{ props: { headers } }">
                  <thead class="v-data-table-header">
                    <tr>
                      <th v-for="(item, index) in headers" :key="item.text + 'header' + index" class="text-nowrap"
                      v-bind:class="[
                          item.sortable ? 'sortable' : '',
                          sortByHeadersListCreate.sortBy == item.value
                            ? 'active'
                            : '',
                          sortByHeadersListCreate.descending
                            ? 'desc'
                            : 'asc',
                        ]" @click="item.sortable ? changeSortListCreated(item.value) : ''">
                        <input v-if="index == 0" @click="checkAllCreate" class="check-all-list-create"
                          type="checkbox" />
                        <span v-else>{{ item.text }}</span>
                        <v-icon v-if="item.sortable" class="v-data-table-header__icon" small>mdi-arrow-up</v-icon>
                      </th>
                    </tr>
                  </thead>
                </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id + '-data-create-' + index" :id="'highlight' + index"
                    @click="changeCellHighlight(index)">
                    <td>
                      <input class="checkbox-delete-data-created" :value="item.id" type="checkbox" @change="checkCreate(item.id)" />
                    </td>
                    <td class="text-nowrap">{{ String(item.contract_fiscal_year).slice(-2) }}年度 (第{{ item.health_guidance_period }}期)</td>
                    <td class="text-nowrap">{{ String(item.patient_id).padStart(10, "0") }}</td>
                    <td class="text-nowrap">{{ item.patient_name_kana }}</td>
                    <td class="text-nowrap">
                      {{ item.patient_birthday_and_sex }}
                    </td>
                    <td class="text-nowrap">
                      {{ item.use_ticket_ref_number }}
                    </td>
                    <td class="text-nowrap">{{ item.health_guidance_div }}</td>
                    <td class="text-nowrap">{{ item.insurer_id }}</td>
                    <td class="text-nowrap">
                      {{ item.health_guidance_contract_div }}
                    </td>
                    <td class="text-nowrap">{{ item.person_familiy_div }}</td>
                    <td class="text-nowrap">{{ item.payment_agency_id }}</td>
                    <td class="text-nowrap">
                      {{ item.claim_report_to_organ_name_and_code }}
                    </td>
                    <td class="text-nowrap">{{ item.claim_timing_div == 40 && item.support_stop_reason ? getExecutionTimeCode(item.support_stop_reason) :  code_time_of_making_invoice[item.claim_timing_div] }}</td>
                    <td class="text-nowrap">{{ item.hg_date }}</td>
                    <td class="text-nowrap">{{ item.claim_date }}</td>
                    <td class="text-nowrap">
                      {{ item.requried_support_point_and_done_support_point }}
                    </td>
                    <td class="text-nowrap">
                      {{ item.calculated_unit_price_01 }}
                    </td>
                    <td class="text-nowrap">{{ item.insurer_claim_amount }}</td>
                    <td class="text-nowrap">
                      {{ item.calculated_unit_price_02 }}
                    </td>
                    <td class="text-nowrap">{{ item.calculated_amount_02 }}</td>
                    <td class="text-nowrap">
                      {{ item.tax_rate }}% {{ getCodeFraction[item.tax_fractionating_div] }}
                    </td>
                    <td class="text-nowrap">
                      {{ item.hg_xml_output_datetime }}
                    </td>

                    <td class="text-nowrap">
                      <a @click="detail(item.id, 'Update')"
                        class="text-decoration-none text-primary">請求編集
                      </a>
                      <a @click="detail_claim_memo(item)" class="text-decoration-none text-primary">
                        <i  v-if="item.claim_memo" class=" ml-2 fa fa-pencil"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <Pagination :total-page="totalPageCreated" :current-page="currentPageCreated"
                      @onHandleChangePage="onHandleChangePageCreated" />
          <div class="col-12 text-right margin-top-20">
            <button @click="popupDeleteListCreated" type="button" class="btn btn-grey mt-5">
              <i class="fa fa-trash" aria-hidden="true"></i>
              取消
            </button>
          </div>
          <!-- Created Tab End -->
        </div>
      </div>
    </div>
    <slide>
      <Update v-if="component === 'Update'" :dataDetailId="idSelected" :key="randomString" :isShowNavigation="randomString" :checkViewOrEdit="true" @reload="reload">
      </Update>
    </slide>
    <Dialog :maxWidth="600" :isShow="isShowDialogDeleteListCreate">
      <v-card>
        <v-container>
          <v-card-title> 確認 </v-card-title>
          <div class="m-3">請求・報告書データ作成取消処理を実行します。よろしいですか？</div>
          <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
            <button type="button" class="btn btn-secondary" @click="isShowDialogDeleteListCreate = false">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="deleteListCreated()">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
    <Dialog :maxWidth="600" :isShow="isShowDialogSaveNotCreate">
      <v-card>
        <v-container>
          <v-card-title> 確認 </v-card-title>
          <div class="m-3">請求・報告書データ作成処理を実行します。よろしいですか？</div>
          <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
            <button type="button" class="btn btn-secondary" @click="isShowDialogSaveNotCreate = false">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="saveNotCreate(), isShowDialogSaveNotCreate = false">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
    <Dialog :maxWidth="600" :isShow="isShowDialogError">
      <v-card>
        <v-container>
          <div class="m-3">
            {{ dataCreateDialogError.msg }}
          </div>
          <v-card-actions>
            <button type="button" class="btn btn-secondary m-auto" @click="isShowDialogError = false">
              キャンセル
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
    <Dialog :maxWidth="600" :isShow="isShowDetailClaimMemo">
      <v-card>
        <v-container>
          <div class="m-3">
            <div v-html="detail_claim_memo_data"></div>
          </div>
          <v-card-actions>
            <button type="button" class="btn btn-secondary m-auto" @click="isShowDetailClaimMemo = false">
              Ok
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
  </div>
</template>
<script>
import Pagination from "@/components/common/Pagination";
import {CODE_REASON_TERMINATE, MESSAGE} from "@/common/constants";
import InvoiceDataReport from "@/services/api/InvoiceDataReport";
import { mapMutations } from "vuex";
import { SUCCESS } from "@/constants";
import moment from "moment";
import mixins from "@/mixins";
import { CODE_GENDER,CODE_TIME_OF_MAKING_INVOICE, ENVS } from "@/common/constants";
import { formInvoiceDataReport } from "../../common/schemaValidation";
import Dialog from "@/components/common/Dialog";
import Slide from "@/components/Slide";
import Update from "@/pages/Invoice-data-report/update";
import _ from "lodash";

export default {
    name: "list",
    components: {
        Pagination,
        Dialog,
        Slide,
        Update,
    },
    data() {
        return {
            randomString: "",
            isShowDialogSaveNotCreate: false,
            checkTab: 1,
            data_delete_reception_id: [],
            detail_claim_memo_data: null,
            isShowDetailClaimMemo: false,
            sortByHeadersListNotCreate: {
                sortBy: null,
                descending: false,
            },
            sortByHeadersListCreate: {
                sortBy: null,
                descending: false,
            },
            headersListNotCreate: [
                { text: "", align: "start", sortable: false, value: "" },
                { text: "", sortable: false, value: "" },
                { text: "年度", sortable: true, value: "contract_fiscal_year" },
                { text: "対象者ID", sortable: true, value: "patient_id" },
                { text: "氏名(カナ)", sortable: true, value: "patient_name_kana" },
                {
                    text: "生年月日/性別",
                    sortable: true,
                    value: "patient_birthday_and_sex",
                },
                {
                    text: "利用券整理番号",
                    sortable: true,
                    value: "use_ticket_ref_number",
                },
                { text: "保健指導区分", sortable: true, value: "health_guidance_div" },
                { text: "保険者", sortable: true, value: "insurer_id" },
                {
                    text: "契約区分",
                    sortable: true,
                    value: "health_guidance_contract_div",
                },
                { text: "本家区分", sortable: true, value: "person_familiy_div" },
                { text: "支払代行機関", sortable: true, value: "payment_agency_id" },
                {
                    text: "請求・報告先",
                    sortable: true,
                    value: "where_to_report_invoice",
                },
                { text: "実施時点", sortable: true, value: "execution_time" },
                { text: "対象日", sortable: true, value: "object_date" },
            ],
            headersListCreate: [
                {
                    text: "",
                    align: "start",
                    sortable: false,
                    value: "",
                    class: "text-nowrap",
                },
                {
                    text: "年度",
                    sortable: true,
                    value: "contract_fiscal_year",
                    class: "text-nowrap",
                },
                {
                    text: "対象者ID",
                    sortable: true,
                    value: "patient_id",
                    class: "text-nowrap",
                },
                {
                    text: "氏名(カナ)",
                    sortable: true,
                    value: "patient_name_kana",
                    class: "text-nowrap",
                },
                {
                    text: "生年月日/性別",
                    sortable: true,
                    value: "patient_birthday_and_sex",
                    class: "text-nowrap",
                },
                {
                    text: "利用券整理番号",
                    sortable: true,
                    value: "use_ticket_ref_number",
                    class: "text-nowrap",
                },
                {
                    text: "保健指導区分",
                    sortable: true,
                    value: "health_guidance_div",
                    class: "text-nowrap",
                },
                {
                    text: "保険者",
                    sortable: true,
                    value: "insurer_id",
                    class: "text-nowrap",
                },
                {
                    text: "契約区分",
                    sortable: true,
                    value: "health_guidance_contract_div",
                    class: "text-nowrap",
                },
                {
                    text: "本家区分",
                    sortable: true,
                    value: "person_familiy_div",
                    class: "text-nowrap",
                },
                {
                    text: "支払代行機関",
                    sortable: true,
                    value: "payment_agency_id",
                    class: "text-nowrap",
                },
                {
                    text: "請求・報告先",
                    sortable: true,
                    value: "claim_report_to_organ_name_and_code",
                    class: "text-nowrap",
                },
                {
                    text: "実施時点",
                    sortable: true,
                    value: "claim_timing_div",
                    class: "text-nowrap",
                },
                {
                    text: "対象日",
                    sortable: true,
                    value: "hg_date",
                    class: "text-nowrap",
                },
                {
                    text: "請求日",
                    sortable: true,
                    value: "claim_date",
                    class: "text-nowrap",
                },
                {
                    text: "実施ポイント",
                    sortable: true,
                    value: "requried_support_point_and_done_support_point",
                    class: "text-nowrap",
                },
                {
                    text: "XML単価算定額(円)",
                    sortable: true,
                    value: "calculated_unit_price_01",
                    class: "text-nowrap",
                },
                {
                    text: "保険者請求額(円)",
                    sortable: true,
                    value: "insurer_claim_amount",
                    class: "text-nowrap",
                },
                {
                    text: "請求出力単価算定額(円)",
                    sortable: true,
                    value: "calculated_unit_price_02",
                    class: "text-nowrap",
                },
                {
                    text: "請求出力額(円)",
                    sortable: true,
                    value: "calculated_amount_02",
                    class: "text-nowrap",
                },
                {
                    text: "消費税",
                    sortable: true,
                    value: "tax_fractionating_div",
                    class: "text-nowrap",
                },
                {
                    text: "XML出力",
                    sortable: true,
                    value: "hg_xml_output_datetime",
                    class: "text-nowrap",
                },
                { text: "", sortable: true, value: "", class: "text-nowrap" },
            ],
            component: null,
            idSelected: null,
            isShowDialogError: false,
            isShowDialogDeleteListCreate: false,
            code_gender: [],
            totalPageNotCreated: 1,
            currentPageNotCreated: 1,
            perPageNotCreated: 150,
            getCodeFraction: [],
            listCodeFraction: [],
            listExecutionTime: [],
            listPaymentAgency: [],
            listContractCode: [],
            listFamilyCode: [],
            listDataNotCreated: [],

            listDataCreated: [],
            totalPageCreated: 1,
            currentPageCreated: 1,
            perPageCreated: 150,

            messages: MESSAGE,
            dataSearch: {
                year: String(this.getFiscalYear()),
                patient_name: null,
                patient_ticket: null,
                insurer: null,
                from_date: moment(new Date()).subtract(1, "month").format("YYYY-MM-DD"),
                to_date: moment(new Date()).format("YYYY-MM-DD"),
                health_guide_div: null,
                payment_agency_id: null,
                health_guidance_contract_div: null,
                family_code: null,
                date_invoice: null,
            },
            dataCreate: {
                receptionIds: [],
                checkedRows: [],
                claim_date: moment(new Date()).format("YYYY-MM-DD"),
                tax_rate: ENVS.DEFAULT_TAX_RATE,
                tax_fractionating_div: ENVS.DEFAULT_TAX_RATE_FRACTIONATING_DIV,
            },
            dataCreateErrorDialog: [],
            dataCreateError: [],
            dataCreateDialogError: {
                msg: null,
            },
            code: [],
            insurerArr: [],
            errors: {
                year: "",
                patient_name: "",
                patient_ticket: "",
                to_date: "",
            },
            checkValidateSaveNotCreate: {
                claim_date: null,
                tax_rate: null,
                tax_fractionating_div: null,
            },
            code_time_of_making_invoice: [],
            rotateNavBar: false,
            In_ValRng_TermOver: null,
            supportStopReason: []
        };
    },
    watch: {
        "dataSearch.from_date"() {
            if(!this.dataSearch.from_date){
                this.In_ValRng_TermOver = "支援予定日を入力してください。";
            }
            if (this.dataSearch.from_date && this.dataSearch.to_date) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 180) {
                    this.In_ValRng_TermOver = "対象日の範囲は180日以内で入力してください。";
                }
            }
        },
        "dataSearch.to_date"() {
            if(!this.dataSearch.to_date){
                this.In_ValRng_TermOver = "支援予定日を入力してください。";
            }
            if (this.dataSearch.from_date && this.dataSearch.to_date) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.to_date).diff(moment(this.dataSearch.from_date), "days");
                if (countDay > 180) {
                    this.In_ValRng_TermOver = "対象日の範囲は180日以内で入力してください。";
                }
            }
        },
    },
    computed: {
        options() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            };
        },
        optionYear() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            };
        },
    },
    async created() {
        this.code_time_of_making_invoice = await this.getCodeDefine(CODE_TIME_OF_MAKING_INVOICE);
        this.supportStopReason = await this.getCodeDefine(CODE_REASON_TERMINATE);
        this.code_gender = await this.getCodeDefine(CODE_GENDER, "acronym");
        InvoiceDataReport.getMedicalIndication().then((res) => {
            this.code = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.code.push({
                    text: value,
                    value: key,
                });
            }
        });

        InvoiceDataReport.getInsurer().then((res) => {
            this.insurerArr = res.data.data;
        });

        InvoiceDataReport.getCodeFraction().then((res) => {
            this.listCodeFraction = [];
            this.getCodeFraction = res.data;
            for (const [key, value] of Object.entries(res.data)) {
                this.listCodeFraction.push({
                    text: value,
                    value: key,
                });
            }
        });

        InvoiceDataReport.getPaymentRepresentativeOrganization().then((res) => {
            this.listPaymentAgency = [];
            res.data.data.map((x) => {
                this.listPaymentAgency.push({
                    text: `[${x.agency_code}] ${x.agency_name}`,
                    value: x.id,
                });
            });
        });

        InvoiceDataReport.getCodeMedicalIndication().then((res) => {
            this.listContractCode = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.listContractCode.push({
                    text: value,
                    value: key,
                });
            }
        });

        InvoiceDataReport.getCodeFamily().then((res) => {
            for (const [key, value] of Object.entries(res.data)) {
                this.listFamilyCode.push({
                    text: value,
                    value: key,
                });
            }
        });

        InvoiceDataReport.getExecutionTime().then((res) => {
            for (const [key, value] of Object.entries(res.data)) {
                this.listExecutionTime.push({
                    text: value,
                    value: key,
                });
            }
        });
        // this.getListNotCreated();
        // this.getListCreated();

        $(document).keypress((e) => {
            if (e.which === 13) {
                this.getSearch()
            }
        });
    },
    mixins: [mixins],
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),
        detail_claim_memo(item){
            this.isShowDetailClaimMemo = true;
            this.detail_claim_memo_data = item.claim_memo_rich;
        },
        getExecutionTimeCode(support_stop_reason) {
            return "途中終了（" + this.supportStopReason[support_stop_reason] + "）"
        },

        changeSort(column) {
            this.sortByHeadersListNotCreate.descending = !this.sortByHeadersListNotCreate.descending;
            if (this.sortByHeadersListNotCreate.descending) {
                this.listDataNotCreated = _.orderBy(this.listDataNotCreated, `${column}`, "desc");
            } else {
                this.listDataNotCreated = _.orderBy(this.listDataNotCreated, `${column}`, "asc");
            }
        },

        changeSortListCreated(column) {
            this.sortByHeadersListCreate.descending = !this.sortByHeadersListCreate.descending;
            if (this.sortByHeadersListCreate.descending) {
                this.listDataCreated = _.orderBy(this.listDataCreated, `${column}`, "desc");
            } else {
                this.listDataCreated = _.orderBy(this.listDataCreated, `${column}`, "asc");
            }
        },

        validate(field) {
            formInvoiceDataReport
                .validateAt(field, this.dataSearch)
                .then(() => {
                    this.errors[field] = "";
                })
                .catch((err) => {
                    this.errors[field] = err.message;
                });
        },

        changeCellHighlightOne(index) {
            $(".cellHighlightOne").removeClass("cellHighlightOne");
            $("#highlightOne" + index).addClass("cellHighlightOne");
        },

        changeCellHighlight(index) {
            $(".cellHighlight").removeClass("cellHighlight");
            $("#highlight" + index).addClass("cellHighlight");
        },

        showDialogError(id_rececption, type) {
            if (type == "初回") {
                type = 1;
            } else if (type == "初回未完了") {
                type = 4;
            } else if (type == "実績評価") {
                type = 2;
            } else {
                type = 3;
            }
            this.dataCreateDialogError.msg = this.dataCreateErrorDialog.filter(
                (x) => x.reception_id == id_rececption && x.type == type && x.status == false
            )[0].msg;
            this.isShowDialogError = true;
        },
        validateSaveNotCreate() {
            this.checkValidateSaveNotCreate.claim_date = null;
            this.checkValidateSaveNotCreate.tax_rate = null;
            this.checkValidateSaveNotCreate.tax_fractionating_div = null;

            if (!this.dataCreate.claim_date) {
                this.checkValidateSaveNotCreate.claim_date = "請求日を入力してください。";
            }

            if (this.dataCreate.tax_rate) {
                if (this.dataCreate.tax_rate < 0 || this.dataCreate.tax_rate > 99) {
                    this.checkValidateSaveNotCreate.tax_rate = "消費税率は[0〜99]の範囲で入力してください。";
                }
            } else {
                this.checkValidateSaveNotCreate.tax_rate = "消費税率を入力してください。";
            }

            if (!this.dataCreate.tax_fractionating_div) {
                this.checkValidateSaveNotCreate.tax_fractionating_div = "消費税 端数処理区分を選択してください。";
            }
        },
        /**
         * 未作成一覧 請求データ作成.
         */
        saveNotCreate() {
            if(this.dataCreate.checkedRows.length > 0){
                // チェックのついたレコードを取得.
                const checkData = [];
                const checkbox_list_not_create = $(".checkbox-list-not-create");
                for (let i = 0; i < checkbox_list_not_create.length; i++) {
                    if(checkbox_list_not_create[i].checked){
                        let id = $(checkbox_list_not_create[i]).attr("id");
                        let type = $(checkbox_list_not_create[i]).attr("data-type");
                        if (type == "初回") {
                            checkData.push({
                                reception_id: id,
                                type: 1,
                            })
                        } else if (type == "初回未完了") {
                            checkData.push({
                                reception_id: id,
                                type: 4,
                            })
                        } else if (type == "実績評価") {
                            checkData.push({
                                reception_id: id,
                                type: 2,
                            })
                        } else {
                            checkData.push({
                                reception_id: id,
                                type: 3,
                            })
                        }
                    }
                }
                this.dataCreate.reception = _.uniqWith(checkData, _.isEqual);

                // バリデーション.
                this.validateSaveNotCreate();
                var checkNotCreated = true;
                for (const [key, value] of Object.entries(this.checkValidateSaveNotCreate)) {
                    if (value != null) {
                        checkNotCreated = false;
                        break;
                    }
                }

                // バリデーションOKの場合、バックエンド呼出.
                if (checkNotCreated) {
                    this.setIsLoading(true);
                    InvoiceDataReport.saveNotCreate(this.dataCreate).then((res) => {
                        const { data, status, error } = res;
                        this.setIsLoading(false);

                        if (status !== SUCCESS) {
	                        this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                            return;
                        }

                        this.totalPageCreated = 1;
                        this.currentPageCreated = 1;
                        this.totalPageNotCreated = 1;
                        this.currentPageNotCreated = 1;

                        this.checkAllNotCreate();
                        this.dataCreateErrorDialog = data.data;
                        this.dataCreateError = data.data;
                        if (data.data.filter((x) => x.status == false).length == 0) {
                            this.$toast.open({
                                message: "請求・報告書データ作成の登録が完了しました。",
                                type: "success",
                            });
                            this.getSearch();
                        } else {
                            if(this.$refs["iconErrCreate"]){
                                this.$toast.open({
                                    message: "請求・報告書データ作成の登録が一部完了しました。(一部エラーあり)",
                                    type: "warning",
                                });
                            }
                            this.getSearch();
                        }
                    });
                }
            }
        },
        /**
         * 未作成一覧 ヘッダチェックボックス.
         */
        checkAllNotCreate() {
            this.dataCreate.receptionIds = [];
            this.dataCreate.checkedRows = [];
            let value = document.getElementsByClassName("checkbox-list-not-create");
            if (
                document.getElementsByClassName("check-all-list-not-create")[0].checked
            ) {
                for (let i = 0; i < value.length; i++) {
                    value[i].checked = true;
                    this.dataCreate.receptionIds.push(parseInt(value[i].value));
                    const recKey = value[i].value + "_" + $(value[i]).attr("data-type");
                    this.dataCreate.checkedRows.push(recKey);
                }
            } else {
                for (let i = 0; i < value.length; i++) {
                    value[i].checked = false;
                }
            }
        },
        /**
         * 未作成一覧 行チェックボックス.
         * @param {*} id 
         */
        checkNotCreate(id, execution_time) {
            const check = this.dataCreate.receptionIds.filter((x) => x == id);
            if (check.length > 0) {
                this.dataCreate.receptionIds = this.dataCreate.receptionIds.filter((x) => x != id);
            } else {
                this.dataCreate.receptionIds.push(id);
            }
            const recKey = id + "_" + execution_time;
            const selectedRec = this.dataCreate.checkedRows.filter((x) => x == recKey);
            if (selectedRec.length > 0) {
                this.dataCreate.checkedRows = this.dataCreate.checkedRows.filter((x) => x != recKey);
            } else {
                this.dataCreate.checkedRows.push(recKey);
            }
        },
        /**
         * 
         */
        getSearchChexBox() {
            this.dataSearch.execution_time = [];
            let value = document.getElementsByClassName(
                "value-code-at-the-time-of-making-invoice"
            );
            for (let i = 0; i < value.length; i++) {
                if (value[i].checked == true) {
                    this.dataSearch.execution_time.push(parseInt(value[i].id));
                }
            }
        },
        /**
         * 
         */
        popupDeleteListCreated() {
            let value = document.getElementsByClassName(
                "checkbox-delete-data-created"
            );
            for (let i = 0; i < value.length; i++) {
                if (value[i].checked == true) {
                    this.isShowDialogDeleteListCreate = true;
                    break;
                }
            }
        },
        /**
         * 作成済み一覧 ヘッダチェックボックス
         */
        checkAllCreate() {
            this.data_delete_reception_id = [];
            let value = document.getElementsByClassName("checkbox-delete-data-created");
            if (
                document.getElementsByClassName("check-all-list-create")[0].checked
            ) {
                for (let i = 0; i < value.length; i++) {
                    value[i].checked = true;
                    this.data_delete_reception_id.push(parseInt(value[i].value));
                }
            } else {
                for (let i = 0; i < value.length; i++) {
                    value[i].checked = false;
                }
            }
        },
        /**
         * 作成済み一覧 行チェックボックス.
         * @param {*} id 
         */
        checkCreate(id) {
            const check = this.data_delete_reception_id.filter((x) => x == id);
            if (check.length > 0) {
                this.data_delete_reception_id = this.data_delete_reception_id.filter((x) => x != id);
            } else {
                this.data_delete_reception_id.push(id);
            }            
        },
        /**
         * 作成済一覧 取消処理.
         */
        deleteListCreated() {
            // 行チェックボックスONのレコード取得.
            let value = document.getElementsByClassName(
                "checkbox-delete-data-created"
            );
            const dataDelete = [];
            for (let i = 0; i < value.length; i++) {
                if (value[i].checked == true) {
                    dataDelete.push(parseInt(value[i].value));
                }
            }

            // チェックONのレコードが存在する場合.
            if (dataDelete.length > 0) {
                this.setIsLoading(true);
                InvoiceDataReport.deleteListCreated({
                    claim_prepare_reception_ids: dataDelete,
                }).then((res) => {
                    const { status, error } = res;
                    this.setIsLoading(false);
                    if (status !== SUCCESS) {
	                    this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                        return;
                    }
                    this.$toast.open({
                        message: "請求・報告書データ作成の取消が完了しました。",
                        type: "success",
                    });
                    document.getElementsByClassName("check-all-list-create")[0].checked = false;
                    this.totalPageCreated = 1;
                    this.currentPageCreated = 1;
                    this.totalPageNotCreated = 1;
                    this.currentPageNotCreated = 1;
                    this.getListCreated();
                    this.getListNotCreated();
                });
            }
            this.isShowDialogDeleteListCreate = false;
        },

        /**
         * 作成済一覧データ取得.
         */
        getListCreated() {
            if (!this.In_ValRng_TermOver) {
                formInvoiceDataReport
                    .validate(this.dataSearch, { abortEarly: false })
                    .then(() => {
                        this.errors = {
                            year: "",
                            patient_name: "",
                            patient_ticket: "",
                            to_date: "",
                        };
                        this.getSearchChexBox();
                        this.setIsLoading(true);
                        InvoiceDataReport.getListCreated(
                            this.dataSearch,
                            this.currentPageCreated,
                            this.perPageCreated
                        ).then((res) => {
                            window.scrollTo({
                                behavior: "smooth",
                                top: 0,
                            });
                            const { data, status, error } = res;

                            this.setIsLoading(false);
                            if (status !== SUCCESS) {
	                            this.$toast.error(`[${error?.messageID}] ${error?.message}`);
	                            return;
                            }

                            this.data_delete_reception_id = [];
                            this.listDataCreated = [];
                            console.log("data.data.data", data.data.data, "+".repeat(30));

                            data.data.data.map((x) => {
                                var patient_gender = this.code_gender[x.patient_gender];
                                var patient_birthday = x.patient ? moment(x.patient_birthday).format("YYYY-MM-DD") : "";
                                var requried_support_point = x.claim_prepare_claim.requried_support_point ?? "ー";
                                var done_support_point = x.claim_prepare_claim.done_support_point ?? "ー";
                                this.listDataCreated.push({
                                    id: x.id,
                                    contract_fiscal_year: x.contract_fiscal_year,
                                    health_guidance_period: x.health_guidance_reception?.health_guidance_period,
                                    patient_id: x.patient_id,
                                    patient_name_kana: x.patient_name_kana,
                                    patient_birthday_and_sex: patient_birthday + " / " + patient_gender,
                                    use_ticket_ref_number: parseInt(x.use_ticket_ref_number),
                                    health_guidance_div: this.getHealthGuidanceDiv(
                                        x.health_guidance_div
                                    ),
                                    insurer_id: this.getInsurer(x.insurer_id),
                                    health_guidance_contract_div: this.getHealthGuidanceContract(
                                        x.health_guidance_contract_div
                                    ),
                                    person_familiy_div: this.getFamilyCode(x.person_familiy_div),
                                    payment_agency_id: this.getPaymentAgency(x.payment_agency_id),
                                    claim_report_to_organ_name_and_code: `[${x.claim_report_to_organ_code}] ${x.claim_report_to_organ_name}`,
                                    claim_timing_div: x.claim_timing_div,
                                    hg_date: x.hg_date,
                                    claim_date: x.claim_date,
                                    support_stop_reason: x.support_stop_reason,
                                    requried_support_point_and_done_support_point: done_support_point + " / " + requried_support_point,
                                    calculated_unit_price_01: x.claim_prepare_claim?.calculated_unit_price_01,
                                    insurer_claim_amount: x.claim_prepare_claim?.insurer_claim_amount,
                                    calculated_unit_price_02: x.claim_prepare_claim?.calculated_unit_price_02,
                                    calculated_amount_02: x.claim_prepare_claim?.calculated_amount_02,
                                    tax_rate: x.claim_prepare_claim?.tax_rate,
                                    tax_fractionating_div: x.claim_prepare_claim?.tax_fractionating_div,
                                    hg_xml_output_datetime: x.hg_xml_output_datetime
                                        ? moment(x.hg_xml_output_datetime).format("YYYY-MM-DD")
                                        : null,
                                    claim_memo: x.claim_prepare_claim?.claim_memo,
                                    claim_memo_rich: x.claim_prepare_claim?.claim_memo_rich,
                                });
                            });
                            this.totalPageCreated = data.data.last_page;
                            this.currentPageCreated = data.data.current_page;

                            if ((this.listDataCreated?.length ?? 0) < 1) {
                                this.notifyNoSearchResults("作成済一覧");
                            }
                        });
                    })
                    .catch((err) => {
                        err.inner.forEach((error) => {
                            this.errors[error.path] = error.message;
                        });
                    });
            }
        },

        /**
         * 未作成一覧データ取得.
         */
        getListNotCreated() {
            if (!this.In_ValRng_TermOver) {
                formInvoiceDataReport
                    .validate(this.dataSearch, { abortEarly: false })
                    .then(() => {
                        this.errors = {
                            year: "",
                            patient_name: "",
                            patient_ticket: "",
                            to_date: "",
                        };
                        this.getSearchChexBox();
                        this.setIsLoading(true);
                        InvoiceDataReport.getListNotCreated(
                            this.dataSearch,
                            this.currentPageNotCreated,
                            this.perPageNotCreated
                        ).then((res) => {
                            window.scrollTo({
                                behavior: "smooth",
                                top: 0,
                            });

                            const { data, status, error } = res;
                            this.setIsLoading(false);
                            if (status !== SUCCESS) {
	                            this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                                return;
                            }

                            this.dataCreate.checkedRows = [];
                            this.dataCreate.receptionIds = [];
                            this.listDataNotCreated = [];

                            data.data.data.map((x) => {
                                this.listDataNotCreated.push({
                                    id: x.id,
                                    patient_id: x.patient_id,
                                    contract_fiscal_year: x.contract_fiscal_year,
                                    health_guidance_period: x.health_guidance_period,
                                    patient_name_kana: x.patients.patient_name_kana,
                                    patient_birthday_and_sex:
                                      x.patients.patient_birthday +
                                      " / " +
                                      this.code_gender[x.patients.patient_gender],
                                    use_ticket_ref_number: parseInt(x.patients.use_ticket_ref_number),
                                    health_guidance_div: this.getHealthGuidanceDiv(
                                        x.health_guidance_div
                                    ),
                                    insurer_id: this.getInsurer(x.patients.insurer_id),
                                    health_guidance_contract_div: this.getHealthGuidanceContract(
                                        x.health_guidance_contract_div
                                    ),
                                    person_familiy_div: this.getFamilyCode(x.person_familiy_div),
                                    payment_agency_id: this.getPaymentAgency(x.payment_agency_id),
                                    where_to_report_invoice: this.getWhereToReportInvoice(x),
                                    execution_time: x.get_execution_time,
                                    support_stop_reason: x.support_stop_reason,
                                    object_date: this.getObjectDate(x),
                                });
                            });

                            if ((this.listDataNotCreated?.length ?? 0) < 1) {
                                this.notifyNoSearchResults("未作成一覧");
                            }

                            $(".demo-list").closest(".container-fluid").css("height", this.listDataNotCreated.length ? "100%" : "auto");
                            this.totalPageNotCreated = data.data.last_page;
                            this.currentPageNotCreated = data.data.current_page;
                            setTimeout(this.checkDataError, 300);
                        });
                    })
                    .catch((err) => {
                        err.inner.forEach((error) => {
                            this.errors[error.path] = error.message;
                        });
                    });
            }
        },
        /**
         * 未作成データ 対象日
         * @param {*} item 
         */
        getObjectDate(item) {
            if (
                !item.health_support_flows_id
            ) {
                return null;
            }

            const support_flow_status = item.support_flow_status;

            // 初回未完了の確定日
            if (item.incomplete_first_support_flag) {
                return item.incomplete_first_support_date;
            }

            // [実施時点が初回]/Thời điểm thực hiện là ban đầu
            if (support_flow_status == 20 || support_flow_status == 30 || support_flow_status == 40) {
                if (item.support_exec_div == 10 && item.support_progress_status == 40) {
                    return item.support_date;
                }
            }

            // 実績評価時/Lúc đánh giá thực trạng
            if (support_flow_status == 30) {
                if (item.support_exec_div == 30 && item.support_progress_status == 40) {
                    return item.support_date;
                }
            }

            // 施時点 途中終了/Thời điểm thực hiện Dừng giữa chừng
            if (support_flow_status == 40 && item.health_guidance_div == 1) {
                return item.support_stop_setting_date;
            }

            return null;
        },

        /**
         * 保健指導区分取得
         * @param {*} code 
         */
        getHealthGuidanceDiv(code) {
            const value = this.code.filter((x) => x.value == code);
            if (value.length > 0) {
                return value[0].text;
            }
            return null;
        },

        /**
         * 
         * @param {*} item 
         */
        getWhereToReportInvoice(item) {
            if (item.payment_agency) {
                return `[${item.payment_agency.agency_code}] ${item.payment_agency.agency_name}`;
            }
            return this.getInsurer(item.patients.insurer_id);
        },

        /**
         * 保険者取得.
         * @param {*} insurer_id 
         */
        getInsurer(insurer_id) {
            if (insurer_id && insurer_id != undefined) {
                let insurer = this.insurerArr.filter((x) => x.id == insurer_id)[0];
                return `[${insurer.client_organ_code}] ${insurer.client_organ_name}`;
            }
            return null;
        },

        /**
         * 保健指導契約区分 取得. 
         * @param {*} health_guidance_contract_div 
         */
        getHealthGuidanceContract(health_guidance_contract_div) {
            if (
                health_guidance_contract_div &&
                health_guidance_contract_div != undefined
            ) {
                let contractCode = this.listContractCode.filter(
                    (x) => x.value == health_guidance_contract_div
                )[0];
                return contractCode.text;
            }
            return null;
        },

        /**
         * 本人家族区分
         * @param {*} person_familiy_div 
         */
        getFamilyCode(person_familiy_div) {
            if (person_familiy_div && person_familiy_div != undefined) {
                let familyCode = this.listFamilyCode.filter(
                    (x) => x.value == person_familiy_div
                )[0];
                return familyCode.text;
            }
            return null;
        },

        /**
         * 支払代行機関
         * @param {*} payment_agency_id 
         */
        getPaymentAgency(payment_agency_id) {
            if (payment_agency_id && payment_agency_id != undefined) {
                let paymentAgency = this.listPaymentAgency.filter(
                    (x) => x.value == payment_agency_id
                );
                return paymentAgency[0]?.text;
            }
            return null;
        },

        /**
         * 未作成一覧 ページ変更.
         * @param {*} pageNumber 
         */
        onHandleChangePageNotCreated(pageNumber) {
            this.currentPageNotCreated = pageNumber;
            this.getListNotCreated();
        },

        /**
         * 作成済み一覧 ページ変更.
         * @param {*} pageNumber 
         */
        onHandleChangePageCreated(pageNumber) {
            this.currentPageCreated = pageNumber;
            this.getListCreated();
        },

        /**
         * 検索条件クリア.
         */
        clearAllSearch() {
            this.dataSearch = {
                year: String(this.getFiscalYear()),
                patient_name: null,
                patient_ticket: null,
                insurer: null,
                from_date: moment(new Date()).subtract(1, "month").format("YYYY-MM-DD"),
                to_date: moment(new Date()).format("YYYY-MM-DD"),
                health_guide_div: null,
                payment_agency_id: null,
                health_guidance_contract_div: null,
                family_code: null,
                date_invoice: null,
            };
            this.listDataCreated = [];
            this.listDataNotCreated = [];
            let value = document.getElementsByClassName(
                "value-code-at-the-time-of-making-invoice"
            );
            for (let i = 0; i < value.length; i++) {
                value[i].checked = true;
            }

            this.totalPageCreated = 1;
            this.currentPageCreated = 1;
            this.totalPageNotCreated = 1;
            this.currentPageNotCreated = 1;
            this.getListNotCreated();
            this.getListCreated();
        },
        detail(id, type) {
            this.idSelected = id;
            this.component = type;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
        reload() {
            this.getListCreated();
        },
        async getSearch() {
            this.currentPageNotCreated = 1;
            this.currentPageCreated = 1;
            await this.getListNotCreated();
            await this.getListCreated();
        },
        checkDataError(){
            if(this.$refs["iconErrCreate"]){
                this.$refs["iconErrCreate"].map((x) => {
                    x.classList.add("d-none");
                })
            }
            let value = document.getElementsByClassName("checkbox-list-not-create");
            for (let i = 0; i < value.length; i++) {
                value[i].checked = false;
            }
            this.dataCreateError.map(x => {
                this.$refs["iconErrCreate"].map((y) => {
                    var type = 3;
                    var get_execution_time = $(y).attr("data-type")

                    if (get_execution_time == "初回") {
                        type = 1;
                    } else if (get_execution_time == "初回未完了") {
                        type = 4;
                    } else if (get_execution_time == "実績評価") {
                        type = 2;
                    }
                    if (x.reception_id == y.id && x.status == false && x.type == type) {
                        y.classList.remove("d-none");
                    }
                });
            });
            this.dataCreateError.map(x => {
                this.dataCreate.receptionIds.push(x.reception_id)
                let value = document.getElementsByClassName("checkbox-list-not-create");
                for (let i = 0; i < value.length; i++) {
                    var get_execution_time = $(value[i]).attr("data-type");
                    var type = 3;
                    if (get_execution_time == "初回") {
                        type = 1;
                    } else if (get_execution_time == "初回未完了") {
                        type = 4;
                    } else if (get_execution_time == "実績評価") {
                        type = 2;
                    }
                    if (x.reception_id == value[i].id && x.status == false && x.type == type) {
                        value[i].checked = true;
                        this.dataCreate.checkedRows.push(x.reception_id + "_" + x.type);
                    }
                }
            });

            this.dataCreateError = [];
        }
    },
    destroyed() {
        $(document).unbind()
    }
};
</script>
<style lang="sass" scoped>
@import "../../styles/common"
.percent
  top: 1px
  right: 1px
  font-size: 15px
  background: #e7e7e7
  padding: 7px
  border-radius: 0px 4px 4px 0px
.cellHighlight
  background-color: #d2e3fb
.cellHighlightOne
  background-color: #d2e3fb
.tab-content>.active
  display: flex
  flex-direction: column
  height: 100%
  overflow: auto
.wrapper .main .content .container-fluid .data-table-container
  padding: 0
  .data-table
    margin: 0
.container-pagination
  margin: 0
.margin-top-20
  margin-top: -20px !important
</style>
