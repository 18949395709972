<template>
    <div v-if="tokenExpired">
        このフォームは有効期限切れです。
    </div>
    <div v-else>
        <div class="dashboard-assistance-area">
            <v-card>
                <v-card-title v-if="!formLayout" class="justify-center">
                    <div class="mb-3">
                        <h4>保健指導　継続支援入力フォーム</h4>
                    </div>
                </v-card-title>
                <v-container>
                    <div>現在の体重・腹囲と各項目別（食習慣、運動習慣、喫煙習慣、休養習慣、その他の生活習慣の5項目）の目標達成状況を教えてください。</div>
                    <div>※同じ項目の中で目標を複数立てている場合は、１つでも継続できていたら「達成」を選んでください。</div>
                    <div>また、どの目標が達成できたか(できなかったか)、最下部の「取り組み状況について」欄に詳細を入力ください。</div>
                </v-container>
                <div v-if="formLayout" class="text-center">
                    <div v-if="this.formLayout.patient_eval_form_title_01" class="my-1">
                    <h3>{{ this.formLayout.patient_eval_form_title_01 }}</h3>
                    </div>
                    <div v-if="this.formLayout.patient_eval_form_title_02" class="my-1">
                    <h4>{{ this.formLayout.patient_eval_form_title_02 }}</h4>
                    </div>
                    <div v-if="this.formLayout.patient_eval_form_desc_01" class="text-left my-1 mx-5">
                    <pre>{{ this.formLayout.patient_eval_form_desc_01 }}</pre>
                    </div>
                </div>
                <v-container>
                    <div class="form-group mt-4 mb-4">
                        <label for="weight">
                            {{ $t('dashboard_support.form_input_object.weight') }}(kg)
                        </label>
                        <span class="required"></span>
                        <div class="weight-field">
                            <input
                                ref="fieldWeight"
                                type="number"
                                @keyup="handleChangeField"
                                v-model="formRequest.weight"
                                name="weight"
                                class="form-control"
                                step="0.1"
                                min="0.0"
                                max="999.9"
                                @change="handleChangeField"
                            />
                            <span>kg</span>
                        </div>
                        <span
                            :class="{'showMessage': errors.weight}"
                            class="text-danger messageError"
                        >{{ errors.weight || 'error' }}</span>
                    </div>
                    <div class="form-group mt-4 mb-4">
                        <label for="waist-circumference">
                            {{ $t('dashboard_support.form_input_object.waist_circumference') }} (cm)
                        </label>
                        <template v-if="is4th">
                            <span class="required"></span>
                        </template>
                        <template v-else>
                            <span class="ml-3">(計測している場合のみ入力)</span>
                        </template>
                        <input
                            ref="fieldWaistCircumference"
                            type="number"
                            @keyup="handleChangeField"
                            v-model="formRequest.waistCircumference"
                            class="form-control"
                            name="waistCircumference"
                            step="0.1"
                            min="0.0"
                            max="999.9"
                            @change="handleChangeField"
                        />
                        <span
                            :class="{'showMessage': errors.waistCircumference}"
                            class="text-danger messageError">{{ errors?.waistCircumference ? errors.waistCircumference : 'error'}}</span>
                    </div>

                    <!-- 食生活 -->
                    <div class="form-group mt-4 mb-4">
                        <div>
                            <label for="eating-habit">
                                <v-template v-if="is4th">
                                    {{ $t('dashboard_support.form_input_object.option_change_eating_habits') }}
                                </v-template>
                                <v-template v-else>
                                    {{ $t('dashboard_support.form_input_object_3rd.option_change_eating_habits') }}
                                </v-template>
                            </label>
                            <span class="required"></span>
                            <div class="plan" v-if="is4th && firstPlans.activity_plan_eating">
                                計画：{{ firstPlans.activity_plan_eating }}
                            </div>
                        </div>
                        <select
                            ref="fieldChangeEatingHabits"
                            class="form-select"
                            name="eatingHabit"
                            v-model="formRequest.eatingHabit"
                            @change="handleChangeField"
                        >
                        <option v-for="(optionItem, key) in optionEatHabit" :value="optionItem.code" :key="key">{{optionItem.name}}</option>
                        </select>
                        <span :class="{'showMessage': errors.eatingHabit}" class="text-danger messageError">{{ errors.eatingHabit || 'error' }}</span>
                    </div>

                    <!-- 運動 -->
                    <div class="form-group mt-4 mb-4">
                        <div>
                            <label for="exercise-change">
                                <v-template v-if="is4th">
                                    {{ $t('dashboard_support.form_input_object.option_change_exercise') }}
                                </v-template>
                                <v-template v-else>
                                    {{ $t('dashboard_support.form_input_object_3rd.option_change_exercise') }}
                                </v-template>
                            </label>
                            <span class="required"></span>
                            <div class="plan" v-if="is4th && firstPlans.activity_plan_exercise">
                                計画：{{ firstPlans.activity_plan_exercise }}
                            </div>
                        </div>

                        <select
                            ref="fieldChangeExercise"
                            @change="handleChangeField"
                            name="exerciseChange"
                            class="form-select"
                            v-model="formRequest.exerciseChange"
                        >
                        <option v-for="(optionItem, key) in optionsExerciseChange" :value="optionItem.code" :key="key">{{optionItem.name}}</option>
                        </select>
                        <span :class="{'showMessage': errors.exerciseChange}" class="text-danger messageError">{{ errors.exerciseChange || 'error' }}</span>
                    </div>

                    <!-- 喫煙 -->
                    <div class="form-group mt-4 mb-4">
                        <div>
                            <label for="smoke">
                                <v-template v-if="is4th">
                                    {{ $t('dashboard_support.form_input_object.option_status_smoking') }}
                                </v-template>
                                <v-template v-else>
                                    {{ $t('dashboard_support.form_input_object_3rd.option_status_smoking') }}
                                </v-template>
                            </label>
                            <span class="required"></span>
                            <div class="plan" v-if="is4th && firstPlans.activity_plan_smoking">
                                計画：{{ firstPlans.activity_plan_smoking }}
                            </div>
                        </div>

                        <select
                            ref="fieldStatusSmoking"
                            class="form-select"
                            @change="handleChangeField"
                            name="smokeChange"
                            v-model="formRequest.smokeChange"
                        >
                            <option v-for="(optionItem, key) in optionsSmokeChange" :value="optionItem.code" :key="key">{{optionItem.name}}</option>
                        </select>
                        <span :class="{'showMessage': errors.smokeChange}" class="text-danger messageError">{{ errors.smokeChange || 'error' }}</span>
                    </div>

                    <!-- 休養 -->
                    <div class="form-group mt-4 mb-4" v-if="is4th">
                        <div>
                            <label for="rest">
                                {{ $t('dashboard_support.form_input_object.option_status_rest') }}
                            </label>
                            <span class="required"></span>
                            <div class="plan" v-if="is4th && firstPlans.activity_plan_rest">
                                計画：{{ firstPlans.activity_plan_rest }}
                            </div>
                        </div>

                        <select
                            ref="fieldStatusRest"
                            class="form-select"
                            @change="handleChangeField"
                            name="restChange"
                            v-model="formRequest.restChange"
                        >
                            <option v-for="(optionItem, key) in optionsRestChange" :value="optionItem.code" :key="key">{{optionItem.name}}</option>
                        </select>
                        <span :class="{'showMessage': errors.restChange}" class="text-danger messageError">{{ errors.restChange || 'error' }}</span>
                    </div>

                    <!-- その他の生活習慣 -->
                    <div class="form-group mt-4 mb-4" v-if="is4th">
                        <div>
                            <label for="else">
                                {{ $t('dashboard_support.form_input_object.option_status_else') }}
                            </label>
                            <span class="required"></span>
                            <div class="plan" v-if="is4th && firstPlans.activity_plan_else">
                                計画：{{ firstPlans.activity_plan_else }}
                            </div>
                        </div>

                        <select
                            ref="fieldStatusElse"
                            class="form-select"
                            @change="handleChangeField"
                            name="elseChange"
                            v-model="formRequest.elseChange"
                        >
                            <option v-for="(optionItem, key) in optionsElseChange" :value="optionItem.code" :key="key">{{optionItem.name}}</option>
                        </select>
                        <span :class="{'showMessage': errors.elseChange}" class="text-danger messageError">{{ errors.elseChange || 'error' }}</span>
                    </div>

                    <!-- 取組状況について -->
                    <div class="form-group mt-4 mb-4">
                        <label for="status-of-initiatives">
                            {{ $t('dashboard_support.form_input_object.status_initiatives') }}
                        </label>
                    <span class="required"></span>
                    <span class="ml-5">( {{ formRequest.statusOfInitiative?.length ?? 0 }} / {{ STATUS_INITIATIVE }} )</span>
                    <div>
                        <div>～複数目標を立てていた場合の「取り組み状況について」欄の記載例～</div>
                        <div>（例）食習慣の目標で複数立てていた場合の記載内容</div>
                        <div>①夕食の米を半分量にする→継続できたので達成</div>
                        <div>②野菜から食べる→できた日とできなかった日があった。毎食野菜を用意することが難しかった。</div>
                    </div>
                    <textarea
                            ref="fieldStatusInitiatives"
                            v-model="formRequest.statusOfInitiative"
                            name="statusOfInitiative"
                            id="status-of-initiatives"
                            cols="30"
                            rows="10"
                            class="form-control"
                            @keyup="handleChangeField"
                            :maxlength="STATUS_INITIATIVE"
                    >
                        </textarea>
                    <span :class="{'showMessage': errors.statusOfInitiative}" class="text-danger messageError">{{ errors.statusOfInitiative || 'error' }}</span>

                    </div>
                    <v-card-actions class="d-flex justify-center align-center">
                        <Button color="info" @click="onHandleSubmit" :rounded="false">
                            フォーム送信
                        </Button>
                    </v-card-actions>
                    <div v-if="doneSubmit" class="mt-5 text-center text-danger">
                        <h2>
                            回答フォームの送信が完了しました。
                            <br/>
                            ご協力ありがとうございました。
                        </h2>
                    </div>
                </v-container>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Button from "@/components/common/Button";
import { schemaValidationFormInputClient } from "@/common/schemaValidation";
import mixins from "@/mixins";
import {In_MaxLen, In_Required, KeyWaist, KeyWeight, Sel_Required} from "@/common/constants";
import ClientRepository from "@/services/api/ClientRepository";
import {checkIsEmpty} from "@/common/functions";
import L2 from "@/services/api/L2";

export default {
    name: "FormInputObject",
    components: { Button },
    mixins: [
        mixins
    ],
    data() {
        return {
            STATUS_INITIATIVE: 500,
            formRequest: {
                weight: null,
                waistCircumference: null,
                eatingHabit: null,
                exerciseChange: null,
                smokeChange: null,
                restChange: null,
                elseChange: null,
                statusOfInitiative: null,
            },
            errors: {},
            optionEatHabit: [],
            optionsExerciseChange: [],
            optionsSmokeChange: [],
            optionsRestChange: [],
            optionsElseChange: [],
            schemaValidate: undefined,
            doneSubmit: false,
            formLayout: null,
            tokenExpired: false,
            firstPlans: {},
            healthGuidancePeriod: 3,
        };
    },
    created() {
        this.showLoading()
        this.schemaValidate = schemaValidationFormInputClient
        if (!this.$route.query || !this.$route.query.token) {
            this.notifyError("You do not have access this url!")
            this.$router.replace({
                path: "/"
            })
        }

        L2.checkTokenExpiration( this.$route.query.token)
            .then((res) => {
                if (res.status == 200) {
                    this.tokenExpired = !res.data.data;
                }
            });

        this.hideLoading()
    },
    computed: {
        ...mapGetters({
            segment: "dashboard/getSegment"
        }),

        is4th() {
            return this.healthGuidancePeriod >= 4;
        },

        excludeFields() {
            return this.is4th ? [] : ["restChange", "elseChange"];
        }
    },
    mounted() {
        L2.getOptionPlan(
            {
                token: this.$route.query.token,
            })
            .then((res) => {
                this.optionEatHabit = [{
                    code: "",
                    name: ""
                }].concat(res.data.data.plan_eating);

                this.optionsExerciseChange = [{
                    code: "",
                    name: ""
                }].concat(res.data.data.plan_activity);

                this.optionsSmokeChange = [{
                    code: "",
                    name: ""
                }].concat(res.data.data.plan_smocking);

                this.optionsRestChange = [{
                    code: "",
                    name: ""
                }].concat(res.data.data.plan_rest);

                this.optionsElseChange = [{
                    code: "",
                    name: ""
                }].concat(res.data.data.plan_else);
            });
        L2.getFormLayoutControl(this.$route.query.token)
            .then((res) => {
                const l = res.data.data;
                if (l == null || Object.keys(l).length < 1) {
                    this.formLayout = null;
                } else {
                    this.formLayout = res.data.data;
                }
            });
        L2.getFirstPlans(this.$route.query.token)
            .then((res) => {
                if (res.data) {
                    this.firstPlans = res.data;
                    this.healthGuidancePeriod = res.data.health_guidance_period ?? 3;
                }
            });
    },
    methods: {
        handleChangeField(e) {
            this.doneSubmit = false;
            this.onValidForm(e.target.name)
        },
        onValidForm(keyValid) {
            const listFormValid = [
                {
                    name: KeyWeight,
                    label: this.$t("dashboard_support.form_input_object.weight"),
                    caseCheck: [In_Required],
                    ref: this.$refs.fieldWeight,
                },
                {
                    name: KeyWaist,
                    label: this.$t("dashboard_support.form_input_object.waist_circumference"),
                    caseCheck: this.is4th ? [In_Required]: [],
                    ref: this.$refs.fieldWaistCircumference,
                },
                {
                    label: this.is4th
                        ? this.$t("dashboard_support.form_input_object.option_change_eating_habits")
                        : this.$t("dashboard_support.form_input_object_3rd.option_change_eating_habits"),
                    name: "eatingHabit",
                    caseCheck: [Sel_Required],
                    ref: this.$refs.fieldChangeEatingHabits,
                },
                {
                    label: this.is4th
                        ? this.$t("dashboard_support.form_input_object.option_change_exercise")
                        : this.$t("dashboard_support.form_input_object_3rd.option_change_exercise"),
                    name: "exerciseChange",
                    caseCheck: [Sel_Required],
                    ref: this.$refs.fieldChangeExercise,
                },
                {
                    label: this.is4th
                        ? this.$t("dashboard_support.form_input_object.option_status_smoking")
                        : this.$t("dashboard_support.form_input_object_3rd.option_status_smoking"),
                    name: "smokeChange",
                    caseCheck: [Sel_Required],
                    ref: this.$refs.fieldStatusSmoking,
                },
                {
                    label: this.is4th
                        ? this.$t("dashboard_support.form_input_object.option_status_rest")
                        : this.$t("dashboard_support.form_input_object_3rd.option_status_rest"),
                    name: "restChange",
                    caseCheck: [Sel_Required],
                    ref: this.$refs.fieldStatusRest,
                },
                {
                    label: this.is4th
                        ? this.$t("dashboard_support.form_input_object.option_status_else")
                        : this.$t("dashboard_support.form_input_object_3rd.option_status_else"),
                    name: "elseChange",
                    caseCheck: [Sel_Required],
                    ref: this.$refs.fieldStatusElse,
                },
                {
                    label: this.$t("dashboard_support.form_input_object.status_initiatives"),
                    name: "statusOfInitiative",
                    caseCheck: [In_Required, In_MaxLen],
                    ref: this.$refs.fieldStatusInitiative,
                },
            ].filter(({ name }) => !this.excludeFields.includes(name));

            let listError = {}
            let listFieldValid = {}
            let listFieldCustom = {}
            let errorFieldRefs = {};
            const listKeyNumber = [KeyWeight, KeyWaist]
            if (!keyValid) {
                listFieldValid = this.formRequest
            }
            if (keyValid) {
                listFieldValid[keyValid] = this.formRequest[keyValid]
                listError = {...this.errors}
            }

            for (let keyItem in listFieldValid) {
                if (this.excludeFields.includes(keyItem)) {
                    continue;
                }
                const valKeyItem = listFieldValid[keyItem]
                const formValidItem = listFormValid.reduce((preVal, nextVal) => {
                    if (nextVal.name === keyItem) {
                        return nextVal
                    }
                    return preVal
                }, {})

                if (checkIsEmpty(valKeyItem)) {
                    // In_Required
                    if (formValidItem.caseCheck.includes(In_Required)) {
                        listError[keyItem] = formValidItem.label + "を入力してください。"
                        errorFieldRefs[keyItem] = formValidItem.ref;
                    }

                    // Sel_Required
                    if (formValidItem.caseCheck.includes(Sel_Required)) {
                        listError[keyItem] = formValidItem.label + "を選択してください。"
                        errorFieldRefs[keyItem] = formValidItem.ref;
                    }

                    // 値が未入力であっても、任意だった場合は、ここで設定済みのエラーメッセージをクリア.
                    if (!(
                        formValidItem.caseCheck.includes(In_Required) || 
                        formValidItem.caseCheck.includes(Sel_Required))
                    ) {
                        delete listError[keyItem]
                        delete errorFieldRefs[keyItem]
                    }
                }

                if (!checkIsEmpty(valKeyItem)) {
                    if (listKeyNumber.includes(keyItem)) {
                        if (Number(valKeyItem) < 0.1 || Number(valKeyItem) > 999.9) {
                            listError[keyItem] = formValidItem.label + "は[0.1〜999.9]の範囲で入力してください。"
                            errorFieldRefs[keyItem] = formValidItem.ref;
                        } else {
                            listFieldCustom[keyItem] = Number(valKeyItem)
                            delete listError[keyItem]
                            delete errorFieldRefs[keyItem]
                        }
                    }

                    if (formValidItem.caseCheck.includes(Sel_Required)) {
                        delete listError[keyItem]
                        delete errorFieldRefs[keyItem]
                    }
                }

                if (keyItem === "statusOfInitiative" && valKeyItem) {
                    if (valKeyItem.length > this.STATUS_INITIATIVE) {
                        listError[keyItem] = formValidItem.label + `は${STATUS_INITIATIVE}文字以下で入力してください。`
                        errorFieldRefs[keyItem] = formValidItem.ref;
                    } else {
                        delete listError[keyItem]
                        delete errorFieldRefs[keyItem]
                    }
                }
            }

            this.errors = listError

            if (Object.keys(listFieldCustom).length) {
                this.formRequest = {
                    ...this.formRequest,
                    ...listFieldCustom
                }
            }
            return Object.values(errorFieldRefs);
        },
        onHandleSubmit() {
            const errorFieldRefs = this.onValidForm();
            if (errorFieldRefs.length > 0) {
                this.notifyError("入力に誤りがあります。")
                errorFieldRefs[0].scrollIntoView({ behavior: "smooth", block: "center" });
                return
            }
            const payload = {
                ...this.formRequest,
                [KeyWeight]: Number(this.formRequest[KeyWeight]),
                [KeyWaist]: Number(this.formRequest[KeyWaist]),
            }

            const dataUpdated = {
                weight: Math.floor(this.formRequest.weight * 10) / 10,
                waist: Math.floor(this.formRequest.waistCircumference * 10) / 10,
                plan_eating: this.formRequest.eatingHabit,
                plan_activity: this.formRequest.exerciseChange,
                plan_smocking: this.formRequest.smokeChange,
                plan_rest: this.formRequest.restChange,
                plan_else: this.formRequest.elseChange,
                change_plan: this.formRequest.statusOfInitiative
            }

            if (dataUpdated.waist < 0.1) {
                dataUpdated.waist = null;
            }

            this.showLoading();
            this.doneSubmit = false;
            ClientRepository.updateSegmentEvalByPatient(this.$route.query.token, dataUpdated)
                .then(response => {
                    if (response && response.status && response.data) {
                        this.doneSubmit = true;
                        this.notifySuccess("回答フォームの送信が完了しました。画面を閉じてください。")
                        return
                    }
                    this.notifyError("このフォームでの回答を受け付けることができません。")
                })
                .catch(err => {
                    this.notifyError(`送信中にエラーが発生しました。: ${err.message}`)
                }).finally(() => {
                    this.hideLoading()
                })
        },
        ...mapMutations({
            showLoading: "common/showLoading",
            hideLoading: "common/hideLoading"
        }),
    }
};
</script>

<style scoped lang="scss">
.dashboard-assistance-area {
  .required:after {
    content: " *";
    color: red;
    font-weight: 100;
  }
  .messageError {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .showMessage {
    visibility: visible;
    opacity: 1;
  }
}

.weight-field {
    display: flex;
    align-items: center;
    gap: 10px;
}

.plan {
    color: #777777;
    font-size: small;
}
</style>